#news {
	margin-top: 3em;
	margin-bottom: 3em;
	a {
		color: $gris-normal;
	}
	h2 {
		padding-left: 2em;
		padding-right: 2em;
	}
	h3 {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		font-weight: 700;
		font-size: 1.4em;
		// padding: 0.8em 0;
	}
	.entry-text {
		text-align: left;
		color: $gris-normal;
		padding-top: 1em;
		a {
			display: block;
			text-decoration: underline;
			float: right;
			padding-top: 3em;
			padding-right: 1.5em;
		}
	}
}