header {
	margin-bottom: 2em;
}

body.blog,
body.single, 
body.archive {
	.main {
		// border: 1px solid red;
		// word-wrap: break-word;
		word-break: break-all;
		hyphens: auto;
		white-space: normal;
		// margin-top: 2em;
	}
	.alert {
		margin-top: 4em;
	}
	h1.entry-title {
		font-weight: 700;
		color: $gris-normal;
	}
	h2, h3 {
		font-weight: 700;
		color: $gris-normal;
		a {
			color: $gris-normal;
		}
	}
	h3 {
		font-size: 2.1em;
	}
	h3#reply-title {
		font-size: 1.5em;
		font-weight: 400;
		padding-top: 1em;
		border-top: 5px solid $brand-primary;
	}
	article footer {
		padding: 0;
	}
	.widget_text,
	.widget_text_icl {
		h3 {
			color: $brand-primary;
		}
		.textwidget p:last-of-type {
			a {
				margin-right: 1em;
			}
		}
	}
	article {
		padding: 1em;
		// border: 1px solid purple;
	}
	time {
		text-transform: lowercase;
	}
	.entry-summary {
		// border: 1px solid blue;
		margin-top: 2em;
	}
	.enlace-more {
		font-weight: 700;
		color: $gris-normal;
	}
	.widget {
		margin-bottom: 4em; 
	}
}

.widget_recent_entries,
.widget_categories,
.widget_archive,
.widget_twitter-timeline-profile {
	h3 {
		padding: 0.8em 0;
		border-top: 5px solid $brand-primary;
	}
}