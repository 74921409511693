// Tipografía Work Sans, de Google Fonts (https://fonts.google.com/specimen/Work+Sans)

@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,700,900');

$font1: "Work Sans", Helvetica, Arial, sans-serif;

body {
	color: $brand-primary;
	font-family: $font1;
	line-height: 1.6em;
}

a {
	color: $brand-primary;
}

h2 {
	font-size: 2.1em;
	font-weight: 300;
}

h3 {
	font-size: 1.3em;
	line-height: 1.3em;
	font-weight: 300;
}

.navbar-nav {
	font-size: 0.75rem;
}

input,
textarea,
.form-control {
	color: $gris-normal;
	font-family: $font1;
	&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		color: $gris-normal;
	}
	&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color: $gris-normal;
	  opacity:  1;
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
		color: $gris-normal;
		opacity:  1;
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $gris-normal;
	}
}

.gris {
	color: $gris-normal;
}