#boletin {
	margin-left: -15px;
	margin-right: -15px;
	background-color: $brand-primary;
	padding: 1em;
	color: #fff;
	.form-group {
		margin-bottom: 0;
	}
	input[type="submit"] {
		background-color: $gris-oscuro;
		color: $gris-claro;
		cursor: pointer;
		&:hover {
			border-color: $gris-claro;
			background-color: $gris-claro;
			color: $brand-primary;
		}
	}
	.row-quiero {
		padding-top: 1em;
	}
}