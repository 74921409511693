#proyectos {
	margin-top: 1em;
	.row-title {
		text-align: center;
	}
	h2 {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		font-weight: 700;
		font-size: 1.4em;
		padding: 0.8em 0;
		border-top: 5px solid $brand-primary;
	}
	.proyecto {
		position: relative;
		padding: 0;
		// .logo-title, .text-title {
		.no-show-hover {
			opacity: 1;
			transition: opacity 0.2s;
		}
		.show-hover {
			// display: none;
			opacity: 0;
			transition: opacity 0.4s; 
			margin-left: auto;
			margin-right: auto;
			position: absolute;
			top: 20%;
			left: 10%;
			color: $gris-claro;
		}	
		&:hover {
			.logo-title, .text-title {
				// display: none;
				opacity: 0;
				// position: absolute;
				// top: 5%;
			}
			.no-show-hover {
				opacity: 0;
			}
			.show-hover {
				// display: block;
				opacity: 1;
			}
		}
	}
}