.wrap {
	margin-top: 68px;
}
// header .container {
header {
	background-color: #fff;
	// border-bottom: 1px solid $brand-primary;
}

/* .navbar {
	background-color: #fff;
	margin-bottom: 67px;
}
 */
.navbar-collapse {
// .navbar-collapse.show {
	// background-color: #fff;
	// padding-left: 2em;
}

.navbar-brand {
	// border: 1px orange solid;
	img {
		width: 250px;
		height: 32px;
		@include media-breakpoint-up(sm) {
			// width:340px;
			width:255px;
			height:43px;
			// width: 350px;
			// height: 59px;
		}
	}
}

.navbar-toggler-right {
	top: 10px;
	@include media-breakpoint-up(sm) {
	top: 16px;
	}
}

.main {
	padding: 0;
	// border: 1px red solid;
}

.home .carousel {
	margin: 0;
}

.home {
	header {
		margin-bottom: 0;
	}
	section {
		margin-top: 4em;
	}
}

.row-hola p,
.row-title h2 {
	font-weight: 300;
	font-size: 3em;
	line-height: 1.1;
	padding: 1.2em 0;
	margin-bottom: 0;
	@include media-breakpoint-up(xs) {
		font-size: 4em;
		padding: 1.4em 0;
	}
}

/* .row-title {
	text-align: center;
	margin: 4em 0;
	h2 {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		font-weight: 400;
		font-size: 1.4em;
		padding: 0.8em 0;
		border-top: 5px solid $brand-primary;
	}
} */

header {
	&.navbar-default {
		padding: 0;
	}
	.container {
		padding: 0;
	}
}

.social-link {
	padding-top: 0.4em;
	// padding-left: 0.6em;
	img {
		width: 24px;
		margin-left: 0.8em;
	}
	// border: 1px solid green;
}

.navbar-nav {
	margin-left: auto;
}
.nav-link {
	font-size: 16px;
	padding: 10px 10px;
	@include media-breakpoint-up(sm) {
		// font-size: 12px;
		// padding: 6px 8px;
	}
	font-weight: 700;
	.wpml-ls-item & {
		display: inline-block;
		font-weight: 400;
		text-align: center;
		// color: #fff;
		text-align: left;
		margin: 4px;
		padding: 2px 4px;
		transition: 0.3s linear;
		border: 1px solid #00c3b5;
		color: $brand-primary;
	}
	.wpml-ls-item.wpml-ls-current-language & {
		background-color: $brand-primary;
		color: #fff;
	}
	.navbar-light .navbar-nav .wpml-ls-item &:active,
	.navbar-light .navbar-nav .wpml-ls-item &:focus,
	.navbar-light .navbar-nav .wpml-ls-item &:hover {
		color: #fff;
		background-color: $brand-primary;
	}
	.navbar-light .navbar-nav .wpml-ls-item.wpml-ls-current-language &:active,
	.navbar-light .navbar-nav .wpml-ls-item.wpml-ls-current-language &:focus,
	.navbar-light .navbar-nav .wpml-ls-item.wpml-ls-current-language &:hover {
		color: $brand-primary;
		background-color: #fff;
	}
}