#bio {
	a {
		color: #fff;
	}
	.row-bio {
		background-color: $brand-primary;
		color: #fff;
	}
	.imagen-bio {
		padding: 0;
	}
	.enlaces-bio {
		padding: 2em;
		a {
			margin-right: 1em;
			height: 30px;
			width: 30px;
		}
		a:active,
		a:focus,
		a:hover {
			text-decoration: none;
		}
	}
	.texto-bio {
		padding: 1em 1.5em;
		h2 {
			font-weight: 700;
			padding: 0;
		}
	}
	.preg-bio {
		padding: 1.5em 1.5em 1.5em 1.5em;
		@include media-breakpoint-up(sm) {
			padding: 4.5em 1.5em 1.5em 1.5em;
		}
		h3 {
			font-weight: 700;
			padding: 0;
		}
	}
}