.carousel {
	margin-left: -15px;
	margin-right: -15px;
	img.img-slide {
		width: 100%;
	}
}

.carousel-item {
	max-height: 800px;
}

.carousel-item {
	align-items: center;
	justify-content: center;
}

.carousel-caption {
	top: 30%;
}

.entry-title {
	position: relative;
	img {
		display: block;
	}
	.logo-title {
		position: absolute;
		top: 30%;
		left: 45%;
	}
	.text-title {
		position: absolute;
		display: block;
		left: 10%;
		right: 10%;
		top: 50%;
	}
	h3 {
		text-align: center;
		color: #fff;
	}
}