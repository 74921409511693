#servicios {
	.row {
		border: 1px solid $brand-primary;
	}

	.entry-title {
	  // position: relative;
	  // float: left;
	  // margin-right: 20px;
	  // margin-bottom: 20px;
	  // cursor: pointer;
	  // box-shadow: rgba(0,0,0,.2) 3px 5px 5px;
	}

	/**
	* Para el cambio de color de la imagen de cada servicio.
	* Fuente: https://codepen.io/jr/pen/rliKq
	**/
	.entry-title:before {
	  content: "";
	  display: block;
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
		background: none;
	  // transition: all .3s linear;
	}

	img.logo-title {
		height: 32px;
		width: 32px;
	}
	.text-title {
		// padding-top: 1.6em;
		top: 45%; 
		h3 {
			font-size: 1.5em;
			font-weight: 700;
			transition: 0.3s linear;
		}
	}
	.entry-text {
		padding: 2em 1em;
		text-align: center;
		color: $brand-primary;
		p {
			&::after {
				display: block;
				content: " ";
				margin: 0.8em 8em;
				border-bottom: 1px solid $brand-primary;
				transition: 0.3s linear;
			}
			&:last-child::after {
				border-bottom: none;
			}
		}
	}
	.col-servicio {
		padding: 0;
		border-right: 1px solid $brand-primary;
		position: relative;
		.entry-title {
			// perspective: 800px;
			// position: absolute;
		}
		img.logo-title {
			display: block;
			position: absolute;
			transform-style: preserve-3d;
  		transition: transform 1s;
  		background-color: transparent;
		}
		&:last-child {
			border-right: none;
		}
		transition: 0.3s linear;
		&:active, 
		&:focus, 
		&:hover {
			background-color: $brand-primary;
			.entry-title:before {
			  background: $brand-primary;
			  opacity: 0.3;
			}

			img.logo-title {
				transform: rotateY( 180deg );
			}

			// .card {
			img.logo-title {
			  // -webkit-transform: translateX( -100% ) rotateY( -180deg );
			  //    -moz-transform: translateX( -100% ) rotateY( -180deg );
			  //      -o-transform: translateX( -100% ) rotateY( -180deg );
			  //         transform: translateX( -100% ) rotateY( -180deg );
			          // transform: rotateY( -180deg );
			}

			&:first-child .text-title h3 {
				// color: $brand-primary;
			}
			.entry-text {
				color: #fff;
			}
			p::after {
				border-bottom: 1px solid #fff;
			}
			p:last-child::after {
				border-bottom: none;
			}
		}
	}
}