#entradilla {
	display: block;
	text-align: center;
	padding: 0;
	// padding: 1.4em 0;
	.col-xs-12 {
		// width: 100%;
	}
	h2 {
		font-size: 1.2em;
	}
	@include media-breakpoint-up(md) {
		padding: 4em 0;
		h2 {
			font-size: 2.1em;
		}
	}

	p {
		text-align: center;
		display: inline;
		line-height: 1.5;
		@include media-breakpoint-up(lg) {
			display: block;
			line-height: 1.1;
		}
	}
}