#contacto {
	margin-left: -15px;
	margin-right: -15px;
	.fondo-contacto {
		background-color: $gris-claro;
		padding-top: 2em;
	}
	.row {
		margin: 0px;
		// padding: 1em;
	}
	h3 {
		color: $gris-normal;
		font-weight: 700;
		font-size: 1.6em;
	}
	.texto-contacto p {
		font-weight: 700;
		&[class="p2"], 
		&[class="mail-contacto"] a {
			color: $gris-normal;
		}
		&.p1 {
			display: none;
		}
		&:last-child {
			margin-top: 2rem;
			font-weight: 400;
			a {
				font-weight: 700;
			}
		}
	}
	input[type="submit"] {
		cursor: pointer;
		// background-color: transparent;
		// border: none;
		// text-decoration: underline;
		&:active,
		&:focus,
		&:hover {
			background-color: $gris-oscuro;
			color: $gris-claro;
		}
	}
	.btn-primary {
		// color: $gris-normal;
	}
}