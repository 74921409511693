footer {
	text-align: left;
	font-weight: 300;
	padding-top: 1em;
	background-color: $brand-primary;
	color: #fff;
	.footer-above {
		padding-bottom: 2em;
	}
	a {
		color: #fff;
	}
	b {
		font-weight: 700;
		// font-size: 2em;
	}
	p {
		margin: 0;
	}
	.col-logo {
	}
	img.footer-logo {
		// border: 1px solid red;
		width: 140px;
		// height: 43px;
		margin-bottom: 1em;
		text-align: left;
	}
	.col-contacto {
		// border: 1px solid red;
		padding-top: 3em;
	}
	.col-hosting {
		// border: 1px solid red;
		// text-align: center;
		// padding: 2em 6em 2em 0;
		padding-top: 2em;

		// padding: 10%;
		// max-width: 20%;
		// padding: 15%;
		img {
			width: 100px;
			@include media-breakpoint-up(sm) {
				// text-align: center;
				// padding: 0;
				width: 120px;
			}
		}
	}
	.col-redes {
		// border: 1px solid red;
		// text-align: center;
		text-align: left;
		img {
			margin-right: 1em;
			width: 24px;
		}
		@include media-breakpoint-up(md) {
			text-align: right;
		}
		padding-top: 2em;
		padding-right: 2em;
		// width:22px;
		// height:30px;
	}
	.footer-below {
		// border: 1px solid red;
		// padding: 2em 0;
		// padding-bottom: 2em;
		@include media-breakpoint-up(sm) {
			padding: 0;
		}	
	}
	.col-copy {
		// border: 1px solid red;
		text-align: center;
		padding-top: 2em;
		padding-bottom: 2em;
		@include media-breakpoint-up(sm) {
			text-align: left;
			padding-top: 1em;
			padding-bottom: 1em;
		}
	}
	.col-pajaro {
		// border: 1px solid red;
		text-align: center;
		// padding-top: 2em;
		// padding-right: 2em;
		padding-bottom: 2em;
		@include media-breakpoint-up(sm) {
			text-align: right;
			padding-top: 1em;
			padding-right: 0;
			padding-bottom: 1em;
		}
	}
	.row-credits {
		font-size: 10px;
		padding: 1em 0;
	}
}