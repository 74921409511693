// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #00A99D	;
$navbar-light-color:		#00A99D;
// $navbar-light-active-color: #00cc99;

$brand-secondary: 			#009966;

$gris-oscuro:						#5d5d5d;
$gris-normal:						#868686;
$gris-claro:						#f2f2f2;


// $carousel-caption-width:90% !default;
// @include media-breakpoint-up(md) {
// 	$carousel-caption-width:70% !default;
// }

// $carousel-transition: transform .4s !default;
$carousel-transition: opacity 1s !default;