#testimonios {
	padding: 1em;
	color: $gris-normal;
	line-height: 1.2em;
	.col-test {
		padding: 1em;
		position: relative;
	}
	.test-text {
		background-color: $gris-claro;
		border-radius: 12px;
		padding: 0.8em;
		align-self: flex-start;
	}
	.test-text:after {
		content: ' ';
		position: relative;
		left: 35px;
		bottom: -45px;
		border: 20px solid;
		border-color: $gris-claro transparent transparent transparent;
	}
	.test-title {
		margin-top: 2em;
		align-self: flex-end;
		color: $brand-primary;
		.cargo-test {
			font-weight: 700;
		}
	}
}